body {
    margin: 0;
}

#app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background: #EDEDED;
    height: 100%;
    height: 100vh;
}

* {
    font-family: 'Open Sans', sans-serif;
}

#scroller {
    overflow-y: auto;
}

button {
    outline: none;
}


.link-reset {
    text-decoration: none;
    color: inherit;
}
